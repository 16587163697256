import { useEffect, Fragment } from 'react'
import './index.css'
import { useParams } from 'react-router-dom'
import Pacotes from './pacote'

const Pacote = () => {
  const { pacote } = useParams()
  const data = Pacotes.filter((item) => item.urlName === pacote)[0]

  // SCROLL SMOOTH
  const saibamais = (e) => {
    e.preventDefault()
    const distance = getDistanceFromTheTop(e.target) - 60
    /*              Top, Distancia Duracao */
    smoothScrollTo(0, distance, 1000)
  }

  const getDistanceFromTheTop = (element) => {
    const id = element.getAttribute('href')
    return document.querySelector(id).offsetTop
  }

  function smoothScrollTo(endX, endY, duration) {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();
    duration = typeof duration !== "undefined" ? duration : 400;
    const easeInOutQuart = (time, from, distance, duration) => {
      if ((time /= duration / 2) < 1)
        return (distance / 2) * time * time * time * time + from;
      return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
    };
    const timer = setInterval(() => {
      const time = new Date().getTime() - startTime;
      const newX = easeInOutQuart(time, startX, distanceX, duration);
      const newY = easeInOutQuart(time, startY, distanceY, duration);
      if (time >= duration) {
        clearInterval(timer);
      }
      window.scroll(newX, newY);

    }, 1000 / 60);
  }

  const enviarMsg = (e) => {
    e.preventDefault()
    const msg = `Olá, gostaria de saber sobre o pacote de viagem de ${pacote.toLocaleUpperCase()}.`
    window.open(`https://wa.me/5511915638609?text=${msg}`, "_blank");
  }

  useEffect(() => {
    let msg = 'Pacote de viagem para Jerusalém', desc = 'Venha descobrir a cidade santa, com preços promocionais e pacotes completos.'

    document.querySelector('head meta[property="og:title"]').setAttribute('content', msg)

    document.querySelector('head meta[property="og:image"]').setAttribute('content', 'https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/27/72/1a.jpg')

    document.querySelector('head meta[property="og:description"]').setAttribute('content', desc)

    const url = window.location.protocol + "//" + window.location.host + window.location.pathname;
    document.querySelector('head meta[property="og:url"]').setAttribute('content', url)

  }, [])

  if (!data)
    return window.location.href = "/";

  return (
    <div className='package'>
      <header className="parallax-header"
        style={{
          background: `linear-gradient(165deg, rgba(0, 0, 255, 0.1), rgba(0, 0, 255, 0.3)), url(/img/${data.img})`, backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '100vh'
        }}
      >
        <div className="container">
          <div className="header-content">
            <h3>Mais que uma viagem, uma <span className="enfase-color">experiência única</span>  e indescritível!</h3>
            <h4>{data.nome}</h4>
            <a href="#saibamais" className='nav-link' onClick={saibamais}>
              Saiba mais
            </a>
          </div>
        </div>
      </header>

      {
        data?.dias &&
        <div className="container">
          <div className="content row">
            <h2>Roteiro de {data.description}</h2>
            <div className='flex-roteiro'>
              {
                data.dias.map((item, i) => (
                  i % 2 === 0
                    ?
                    <Fragment key={i}>
                      <div className='title left'>
                        <h3>{item.title}</h3>
                        <ul>
                          {item.items.map((r,i) => (
                            <li key={i}>{r}</li>
                          ))
                          }
                        </ul>
                      </div>
                      <div className='day'>{++i}</div>
                      <div className='space'>&emsp;</div>
                    </Fragment>
                    :
                    <Fragment key={i}>
                      <div className='space'>&emsp;</div>
                      <div className='day'>{++i}</div>
                      <div className='title right'>
                        <h3>{item.title}</h3>
                        <ul>
                          {item.items.map((r,i) => (
                            <li key={i}>{r}</li>
                          ))
                          }
                        </ul>
                      </div>
                    </Fragment>
                ))
              }
            </div>
          </div>

          <div className="content row-mobile">
            <h2>Roteiro de {data.description}</h2>
            <div className='flex-roteiro'>
              {
                data.dias.map((item, i) => (
                  <Fragment key={i}>
                    <div className='day'>{++i}</div>
                    <div className='title'>
                      <h3>{item.title}</h3>
                      <ul>
                        {item.items.map((r, i) => (
                          <li key={i}>{r}</li>
                        ))
                        }
                      </ul>
                    </div>
                  </Fragment>

                ))
              }
            </div>
          </div>

          <div className='link-button'>
            <a href="#saibamais" className='nav-link' onClick={saibamais}>
              Adquirir
            </a>
          </div>
        </div>
      }


      <main>
        <div className='content' id="saibamais">
          <h2>Pacote</h2>
        </div>
        <article>
          <section className='preco'>
            <p>{data.description}</p>
            <p>{data.nome}</p>
            <p>a partir de <span className="text-enfase"><small>R$</small> {data.valor} <sup>,00</sup></span></p>

          </section>
          <section className='inclui'>
            <div>
              <h3>Pacote inclui</h3>
              <ul>
                {data.incluso.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
              <h3>Pacote não inclui</h3>
              <ul>
                {data.naoIncluso.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
          </section>

        </article>
        <div className='button-link'>
          <a href="#saibamais" className='nav-link' onClick={enviarMsg}>
            Entre em contato
          </a>
        </div>

      </main>

      <button onClick={enviarMsg}><img src="/img/whatsapp.webp" alt="Entre em contato por WhatsApp" title='Entre em contato via WhatsApp' /></button>
    </div>
  )
}

export default Pacote